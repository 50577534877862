<template>
  <div class="">
    <div class="tihsi font14">
      立即<span @click="$router.push('/downapp')">下载APP</span
      >,随时随地找工作，实时查看面试进度、接收面试通知和Offer管理 。
    </div>
    <!-- 无记录 -->
    <div class="nodata" v-if="!userList.length">
      <img src="../../assets/images/common/search-no.png" />
      <div class="font18" v-if="!loading">当前面试管理空</div>
      <div class="font18" v-if="loading">加载中</div>
    </div>
    <!-- 有记录 -->
    <div class="">
      <el-card class="positionlist">
        <div
          class="box-card p-list"
          v-for="(itemli, indexes) in userList"
          :key="indexes"
        >
          <div class="p-listbox">
            <div class="pl-left font14">
              <div class="l-one">
                <div class="font16">
                  {{ itemli.jobName }}
                  <span class="xinzi">
                    {{ (itemli.salaryLow / 1000) | numFilter }}
                    K-
                    {{ (itemli.salaryHigh / 1000) | numFilter}}
                    K
                  </span>
                  <span class="biaoqian1" v-if="itemli.jobType == 1">Free</span>
                  <span class="biaoqian2" v-if="itemli.status == '0'"
                    >待回复</span
                  >
                  <span class="biaoqian3" v-if="itemli.status == '1'"
                    >待面试</span
                  >
                  <span class="biaoqian4" v-if="itemli.status == '2'"
                    >已拒绝</span
                  >
                  <span class="biaoqian4" v-if="itemli.status == '3'"
                    >未通过</span
                  >
                  <span class="biaoqian1" v-if="itemli.status == '4'"
                    >已通过</span
                  >
                  <span class="biaoqian1" v-if="itemli.status == '5'"
                    >已超时</span
                  >
                </div>
              </div>
              <div class="l-two">项目周期：2021-05-01 — 2021-07-31</div>
              <div class="l-three" v-if="itemli.interviewType == 1">
                {{ "线下面试" }}：{{
                  itemli.interviewAddress + itemli.interviewBuild
                }}
              </div>
              <div class="l-three" v-else>线上面试</div>
            </div>
            <div class="pl-center font14">
              <div class="">联系人：{{ itemli.interviewContact }}</div>
              <div class="">联系方式：{{ itemli.interviewPhone }}</div>
              <div class="">面试时间：{{ itemli.interviewDate }}</div>
            </div>
            <div class="pl-right font14">
              <div class="synum">
                剩余招聘:<span>{{ itemli.jobFree }}</span
                >人
              </div>
              <div class="time">{{ itemli.shortName }}</div>
              <div class="czbtn">
                <img
                  v-if="itemli.companyAvatar"
                  :src="
                    'https://freemen.work/user/file/view/' +
                    itemli.companyAvatar
                  "
                />
                <img v-else src="../../assets/logo.png" />
              </div>
            </div>
          </div>
          <!-- v-if="itemli.status=='0'" -->
          <!-- <div class="btn"  v-if="itemli.status=='0'">
						<el-button class="fail" type="success" @click="agreeInterview(itemli)">接受面试</el-button>
						<el-button class="fail" type="primary" @click="refuseInterview(itemli)">拒绝面试</el-button>
					</div> -->
        </div>
        <div class="nextpage" @click="getMoreList" v-if="moreBtn">
          点击加载更多
        </div>
      </el-card>

      <!-- <div class="paginate" style="position: relative;">
				<el-pagination
					@current-change="handleCurrentChange"
					:current-page="pageInfo.pageNum"
					:page-size="pageInfo.pageSize"
					layout="total, prev, pager, next, jumper"
					:total="pageInfo.count"
				></el-pagination>
			</div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageInfo: {
        pageNo: 1,
        pageSize: 10,
      },
      userList: [],
      moreBtn: false,
      loading: false,
    };
  },
  created() {
    this.getInterviewList();
  },
  mounted() {},
  filters: {
    numFilter(value) {
      let transformVal = Number(value).toFixed(2)
      let realVal = transformVal.substring(0, transformVal.length - 1)

      return Number(realVal)
    }
  },
  methods: {
    getInterviewList() {
      this.loading = true;
      //获取面试列表
      this.$api.getInterviewList("get", this.pageInfo).then((res) => {
        this.loading = false;

        this.userList = this.userList.concat(res.data);
        if (res.data.length < 10) {
          this.moreBtn = false;
        } else {
          this.moreBtn = true;
        }
      });
    },
    getMoreList() {
      //下一页
      this.pageInfo.pageNo++;
      this.getPostRecord();
    },
    agreeInterview(data) {
      //同意
      this.$api
        .saveInterview("post", { interviewId: data.id, status: 1 })
        .then(() => {
          this.userList = [];
          this.pageInfo.pageNo = 1;
          this.getPostRecord();
          this.$message({
            message: "已接受面试",
            type: "success",
          });
        });
    },
    refuseInterview(data) {
      //拒绝
      this.$api
        .saveInterview("post", { interviewId: data.id, status: 2 })
        .then(() => {
          this.userList = [];
          this.pageInfo.pageNo = 1;
          this.getPostRecord();
          this.$message({
            message: "已拒绝面试",
            type: "success",
          });
        });
    },
    handleCurrentChange() {
      // val
      // this.fetchData(val);
    },
  },
};
</script>

<style lang="less" scoped>
	/deep/.el-card {
		border-radius: 0px;
		box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
	}
	/deep/.el-card__body{
		padding: 0px 0;
	}
.tihsi{
	background-color: #444C5F;
	color: #ABB0BA;
	padding: 0px 30px;
	line-height: 44px;
	span{
		color: #00BCFF;
	}
}
.paginate{
	margin: 130px 0 100px 0  !important;
	.el-pagination{
		padding: 0 !important;
	}
}
.positionlist {
	margin-top: 20px;
	.nextpage{
		display: flex;
		align-items: center;
		justify-content: center;
		color: #999999;
		cursor: pointer;
		margin: 15px 0;
	}
	.p-list:hover{
		// transform: scale(1.01);
		background-color: rgba(0, 0, 0, 0.03);
	}
	.p-list {
		// margin-bottom: 20px;
		border-bottom: 1px solid #F3F3F3;
		padding: 20px;
		.p-listbox {
			display: flex;
			align-items: center;
			// padding-bottom: 20px;
			.pl-left {
				display: flex;
				flex: 1;
				// align-items: center;
				flex-direction: column;
				line-height: 2.2;
				.l-one {
					display: flex;
					align-items: center;
					// justify-content: space-between;
					div {
						color: #111111;
						font-weight: 600;
						// span {
						// 	color: #ff8400;

						// }
						span{
							font-size: 14px;
							font-weight: 500;
						}
						.xinzi{
							color: #666666;
						}
						.biaoqian1 {
							background-color: #e9f9ff;
							color: #00bcff;
							padding: 1px 10px;
							margin-left:10px;
							font-size: 14px;
							font-weight: 500;
						}
						.biaoqian2{
							background-color: #FFEEEE;
							color: #FF2E2E;
							padding: 1px 10px;
							margin-left:10px;
							font-size: 14px;
							font-weight: 500;
						}
						.biaoqian3{
							background-color: #FEECDE;
							color: #FF5400;
							padding: 1px 10px;
							margin-left:10px;
							font-size: 14px;
							font-weight: 500;
						}
						.biaoqian4{
							background-color: #F3F3F3;
							color: #9F9F9F;
							padding: 1px 10px;
							margin-left:10px;
							font-size: 14px;
							font-weight: 500;
						}
					}
				}
				.l-two {
					color: #666666;
				}
				.l-three {
					color: #999999;
					// overflow: hidden;
					// text-overflow:ellipsis;
					// white-space: nowrap;
					// width: 280px;
				}
			}
			.pl-center{
				display: flex;
				// align-items: center;
				justify-content: center;
				flex-direction: column;
				// flex: 1;
				width: 220px;
				line-height: 2.2;
				color: #999999;
				// background-color: #000080;

			}
			.pl-right {
				display: flex;
				align-items: center;
				justify-content: center;
				flex: 1;
				color: #666666;
				div{
					display: flex;
					align-items: center;
					justify-content: center;
					flex: 1;
				}
				.time {
					color: #111111;
				}
				.synum {
					span {
						color: #111111;
					}
				}
				.czbtn {
					display: flex;
					align-items: center;
					justify-content: center;
					img{
						width: 40px;
						height: 40px;
						border-radius: 10px;
					}
				}
			}
		}
	}
}
.nodata {
	background-color: #ffffff;
	// box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 80px 0 96px 0;
	margin-top: 20px;
	div {
		color: #111111;
		margin-top: 20px;
	}

	img {
		width: 260px;
		height: 220px;
	}
}

</style>
